@import "../../../_theme_variables";

/* Top hook CSS */
#htmlcontent_top {
	ul {
		margin:0;
		li {
			padding:0;
			position:relative;
			&:last-child {
				margin-bottom:-1px;
			}
			img {
				width: 100% !important;
				height: auto !important;
			}
			a {
				display:block;
				&:hover {
					.item-html button {
						background:#fff;
						color:#262626;		
					}
				}
			}
			.item-html {
				position:absolute;
				left:0;
				top:0;
				width:100%;
				height:100%;
				display:block;
				text-align:center;
				color:#fff;
				text-transform:uppercase;
				font-size:0;
				&:before {
					display:inline-block;
					width:0;
					content:"";
					vertical-align:middle;
					height:100%;	
				}
				> div {
					display:inline-block;
					vertical-align:middle;
					width:98%;
					font: 300 20px / 30px $font-family;
					font: 300 3vw / 3.5vw $font-family !important;
					@media (min-width: $screen-lg) { // min 1200px
						font: 300 30px / 40px $font-family;
						font: 300 1.65vw / 2vw $font-family !important;
					}
					@media (max-width: $screen-xs-max) { // max 767px
						font: 300 13px / 18px $font-family;
					}
				}
				h2 {
					margin: 0 0 10px;
					margin: 0 0 1vw !important;
					font: normal 60px / 70px $font-custom;
					font: normal 7vw / 7.5vw $font-custom !important;
					@media (min-width: $screen-lg) { // min 1200px
						font: normal 40px / 50px $font-custom;
						font: normal 5.35vw / 5.95vw $font-custom !important;
					}
					@media (max-width: $screen-xs-max) { // max 767px
						font: normal 25px / 30px $font-custom;
					}
					span {
						display:block;	
					}
				}
				p {
					letter-spacing:.33vw;
					margin: 0 0 10px;
					margin: 0 0 2.1vw !important;
				}
				button {
					@include transition (.3s);
					background:none;
					border:2px solid #fff;
					@include border-radius(0);
					font: normal 16px / 24px $font-custom;
					font: normal 1.5vw / 2vw $font-custom !important;
					padding: 7px 20px;
					padding: 1vw 2.3vw !important;
					text-transform:uppercase;
					font-weight:normal;
					@media (min-width: $screen-lg) { // min 1200px
						font: normal 20px / 30px $font-custom;
						padding: 10px 30px;
						font: normal 1vw / 1.5vw $font-custom !important;
						padding: 0.615vw 2.3vw !important;
					}
					@media (max-width: $screen-xs-max) { // max 767px
						padding: 4px 10px;
						font: normal 12px / 18px $font-custom;
						font: normal 2.5vw / 3vw $font-custom !important;
						border:1px solid #fff;
					}
				}
			}
		}
	}
}

/* Top hook CSS */
#htmlcontent_home {
	ul {
		margin:0;
		li {
			padding:0;
			position:relative;
			&.htmlcontent-item-1, &.htmlcontent-item-3, &.htmlcontent-item-5, &.htmlcontent-item-7, &.htmlcontent-item-9 {
				clear:left;
			}
			a {
				background:#262626;
				display:block;
				img {
					opacity:0.75;
					@include transition (.3s);
				}
				&:hover {
					img {
						opacity:1;	
					}
					.item-html button {
						background:#fff;
						color:#262626;		
					}
				}
			}
			&.htmlcontent-item-1 a { background:#fff;}
			&.htmlcontent-item-1, &.htmlcontent-item-4, &.htmlcontent-item-5, &.htmlcontent-item-8, &.htmlcontent-item-9 {
				width:66.07%;
				.item-html {
					> div {
						font: 300 20px / 30px $font-family;
						font: 300 3vw / 3.5vw $font-family !important;
						@media (min-width: $screen-lg) { // min 1200px
							font: 300 30px / 40px $font-family;
							font: 300 1.65vw / 2vw $font-family !important;
						}
						@media (max-width: $screen-xs-max) { // max 767px
							font: 300 13px / 18px $font-family;
						}
					}
					h2 {
						font: normal 40px / 50px $font-custom;
						font: normal 5vw / 6vw $font-custom !important;
						margin: 0 0 10px;
						margin: 0 0 0.62vw !important;
						@media (min-width: $screen-lg) { // min 1200px
							font: normal 30px / 40px $font-custom;
							font: normal 3.4vw / 4.1vw $font-custom !important;
						}
						@media (max-width: $screen-xs-max) { // max 767px
							font: normal 16px / 20px $font-custom;
							margin: 0;
						}
					}
					p {
						letter-spacing:.33vw;
						margin: 0 0 10px;
						margin: 0 0 1.57vw !important;
					}
				}	
			}
			&.htmlcontent-item-2, &.htmlcontent-item-3, &.htmlcontent-item-6, &.htmlcontent-item-7, &.htmlcontent-item-10 {
				width:33.93%;
				.item-html {
					> div {
						font: 300 18px / 24px $font-family;
						font: 300 2.5vw / 3vw $font-family !important;
						@media (min-width: $screen-lg) { // min 1200px
							font: 300 20px / 24px $font-family;
							font: 300 1.3vw / 1.8vw $font-family !important;
						}
						@media (max-width: $screen-xs-max) { // max 767px
							font: 300 12px / 18px $font-family;
						}
					}
					h2 {
						font: normal 40px / 50px $font-custom;
						font: normal 3.5vw / 4.5vw $font-custom !important;
						margin: 0 0 10px;
						margin: 0 0 0.7vw !important;
						@media (min-width: $screen-lg) { // min 1200px
							font: normal 30px / 40px $font-custom;
							font: normal 2.4vw / 2.8vw $font-custom !important;
						}
						@media (max-width: $screen-xs-max) { // max 767px
							font: normal 14px / 18px $font-custom;
							margin: 0;
						}
					}
					p {
						letter-spacing:.33vw;
						margin: 0 0 10px;
						margin: 0 0 2vw !important;
					}
				}
			}
			img {
				width: 100% !important;
				height: auto !important;	
			}
			.item-html {
				position:absolute;
				left:0;
				top:0;
				width:100%;
				height:100%;
				display:block;
				text-align:center;
				color:#fff;
				text-transform:uppercase;
				font-size:0;
				&:before {
					display:inline-block;
					width:0;
					content:"";
					vertical-align:middle;
					height:100%;	
				}
				> div {
					display:inline-block;
					vertical-align:middle;
					width:98%;
				}
				h2 {
					font-family: $font-custom;
					font-weight:normal;
					span {
						display:block;	
					}
				}
				button {
					@include transition (.3s);
					background:none;
					border:2px solid #fff;
					@include border-radius(0);
					font: normal 16px / 24px $font-custom;
					font: normal 1.5vw / 2vw $font-custom !important;
					padding: 7px 20px;
					padding: 1vw 2.3vw !important;
					text-transform:uppercase;
					font-weight:normal;
					@media (min-width: $screen-lg) { // min 1200px
						font: normal 20px / 30px $font-custom;
						padding: 10px 30px;
						font: normal 1vw / 1.5vw $font-custom !important;
						padding: 0.615vw 2.3vw !important;
					}
					@media (max-width: $screen-xs-max) { // max 767px
						padding: 4px 10px;
						font: normal 12px / 18px $font-custom;
						font: normal 2.5vw / 3vw $font-custom !important;
						border:1px solid #fff;
					}
				}
				.dark {
					color:#333;	
				}
			}
		}
	}
}

/* Left hook CSS */
#htmlcontent_left {
}

/* Right hook CSS */
#htmlcontent_right {
}

/* Footer hook CSS */
#htmlcontent_footer {
}